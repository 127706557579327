* {
  box-sizing: border-box;
}

:root {
  --background: #035033;

  --avatar-frame-color: #e8e8e5;

  --color-primary: #f7f7f7;
  --color-black: #000000;
  --color-white: #ffffff;

  --color-gray: #8b8b8b;

  --color-green: #7fd856;
  --color-red: #ff312f;
  --color-blue: #004bad;
  --color-cyan: #5ee0e6;
  --color-purple: #8b52ff;
  --color-yellow: #ffdc03;
  --color-orange: #ff8c00;
}

html, body {
  height: 100dvh;
  width: 100dvw;
  margin: 0;
  padding: 0;
}

body {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #f7f7f7;
  background-color: var(--background);
  background-image:
    url('https://comic.jaybritton.tv/images/Background.svg'),
    radial-gradient(circle, var(--background), #06311f 80%)
  ;
  background-attachment: fixed;
  background-position: top left;
  background-size: 37px, auto;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 2rem;

  & img {
    height: 300px;
    margin-block: -80px -50px;
  }

  & h1 {
    --_stroke-width: 2px;

    font-family: 'Bangers', sans-serif;
    text-align: center;
    color: var(--color-yellow);
    font-size: 3rem;
    margin: 0;

    text-shadow:
      var(--_stroke-width) var(--_stroke-width) 0 black, calc(var(--_stroke-width) * -1) calc(var(--_stroke-width) * -1) 0 black,
      var(--_stroke-width) calc(var(--_stroke-width) * -1) 0 black, calc(var(--_stroke-width) * -1) var(--_stroke-width) 0 black,
      0 var(--_stroke-width) 0 black, 0 calc(var(--_stroke-width) * -1) 0 black,
      var(--_stroke-width) 0 0 black, calc(var(--_stroke-width) * -1) 0 0 black;
  }

  & .status-wrapper {
    display: flex;
    gap: 20px;
    align-items: center;
    
    & .status {
      display: inline-block;
      inline-size: 30px;
      block-size: 30px;
      border-radius: 50%;
      background-color: var(--color-red);

      &.active {
        background-color: var(--color-green);
      }
    }
  }

  & .events {
    flex: 1;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    width: clamp(800px, 100%, 1500px);
    background-color: var(--background);
    border: 4px solid var(--color-yellow);
    border-radius: 5px;
    padding: 1rem;

    .inner {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      font-size: 1.5rem;

      .block {
        border: 1px solid var(--color-yellow);
        border-radius: 6px;

        & .timestamp {
          font-size: 1rem;
          color: var(--color-yellow);
          padding-inline: 10px;
          display: flex;
          align-items: center;
          gap: 15px;
        }
    
        & pre {
          margin: 0;
          border-radius: 5px;
          white-space: pre-wrap;
        }
      }
    }
  }
}

@font-face {
  font-family: 'Bangers';
  src: url('https://assets.jbtv.app/jays-bangers.ttf') format('truetype');
}